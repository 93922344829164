import type { Application } from '@src/@types/app';
import { DEFAULT_FILTERS, DEFAULT_LIMIT, DEFAULT_LIMIT_MOBILE, FILTERS_SCHEME } from '@src/constants/filters';
import { parse } from '@src/helpers/query';

interface IPageQueryConfig {
    ctx: Application.ReduxNextPageContext;
    defaultFiltersMode: string;
}

export const getPageListQuery = ({ ctx, defaultFiltersMode }: IPageQueryConfig) => {
    const {
        store: { getState },
        params: { mediaInfo },
    } = ctx;

    const seoFilters = getState().seo.filters.data;
    const limitMobile = seoFilters?.limitMobile || DEFAULT_FILTERS[defaultFiltersMode].limitMobile;

    return parse({
        defaultValues: {
            ...DEFAULT_FILTERS[defaultFiltersMode],
            ...seoFilters,
            ...(mediaInfo.phone && {
                limit: mediaInfo.phone ? limitMobile || DEFAULT_LIMIT_MOBILE : DEFAULT_LIMIT,
            }),
        },
        query: ctx.query,
        scheme: FILTERS_SCHEME,
    });
};
