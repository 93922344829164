import isEmpty from 'lodash/isEmpty';
import type { ReactElement } from 'react';
import React, { useState, useEffect } from 'react';

import { Banner, BANNER_TYPES } from '@sravni/react-advertisement';

import SidebarBanner from '@src/components/SidebarBanner';
import { BANNER_TYPE_FIXED } from '@src/constants/banners';
import { BottomBannerTypes } from '@src/constants/bottomBannerTypes';
import { useSidebarBanner } from '@src/hooks/useSidebarBanner';
import { useSelectBanners, useIsLoaded } from '@src/reducers/banners';
import { useIsBrandingScrolledDown } from '@src/reducers/branding';

interface IProps {
    enabledTypes?: BottomBannerTypes[];
}

const BottomBannerContainer = ({ enabledTypes = [BottomBannerTypes.SIDEBAR] }: IProps): ReactElement | null => {
    const isScrolledDown = useIsBrandingScrolledDown();
    const [isShowed, setIsShowed] = useState(false);
    const sidebarBannerContent = useSidebarBanner();
    const adBanners = useSelectBanners();
    const isAdBannersLoaded = useIsLoaded();

    useEffect(() => {
        const TIMER_VALUE = 10;

        const timer = setTimeout(() => {
            setIsShowed(true);
        }, TIMER_VALUE * 1000);

        return () => clearTimeout(timer);
    }, []);

    const isBottomBannerShowed = (isShowed || isScrolledDown) && isAdBannersLoaded;

    if (!isBottomBannerShowed) {
        return null;
    }

    const outOfpageBannerContent = adBanners[BANNER_TYPE_FIXED];

    return (
        <>
            {enabledTypes.includes(BottomBannerTypes.OUT_OF_PAGE) && !isEmpty(outOfpageBannerContent) && (
                <Banner banner={outOfpageBannerContent} type={BANNER_TYPES.OUT_OF_PAGE} abName="" />
            )}
            {enabledTypes.includes(BottomBannerTypes.SIDEBAR) && !isEmpty(sidebarBannerContent) && <SidebarBanner />}
        </>
    );
};

export default BottomBannerContainer;
