import type { FC } from 'react';
import React, { memo, useCallback, useMemo } from 'react';

import type { IAdvertising } from '@sravni/types/lib/advertising';
import type { IOrganization } from '@sravni/types/lib/organizations';
import { SidebarBanner as VitrinsSidebarBanner } from '@sravni/vitrina-components';

import type { AffSub4 } from '@src/@types/microcredits';
import { Pixel } from '@src/components/Pixel';
import { sendSidebarClickEvent } from '@src/helpers/analyticsEvents';
import { usePixel } from '@src/hooks/usePixel';
import { useSidebarBanner } from '@src/hooks/useSidebarBanner';

const SidebarBanner: FC = memo(() => {
    const bannerData = useSidebarBanner();

    const affSub4: AffSub4 = useMemo(
        () => ({
            organizationName: bannerData?.organization?.name,
            productName: bannerData?.name,
            organizationId: bannerData?.organization?._id,
            productId: bannerData?._id,
        }),
        [bannerData?._id, bannerData?.name, bannerData?.organization?._id, bannerData?.organization?.name],
    );

    const href = usePixel(bannerData?.advertising?.monetization?.pixels.click, {
        sub: bannerData?.advertising?.advSub,
        source: 'sidebar',
        type: bannerData?.advertising?.productType,
        affSub4,
    });

    const sidebarData = useMemo(
        () => ({
            organization: {
                name: {
                    short: bannerData?.organization?.name,
                    full: bannerData?.organization?.fullName,
                },
            } as IOrganization,
            advertising: (bannerData?.advertising as unknown) as IAdvertising,
            logo: bannerData.organization.logotypes.android,
        }),
        [bannerData],
    );

    const BannerPixel = useMemo(
        () => <Pixel link={bannerData?.advertising?.monetization?.pixels.display} source="sidebar" affSub4={affSub4} />,
        [bannerData?.advertising?.monetization?.pixels.display, affSub4],
    );

    const handleClick = useCallback(() => {
        sendSidebarClickEvent(`${bannerData.organization.name}|${bannerData.name}`, bannerData?.advertising?.cost);

        if (href) {
            window.open(href, '_blank');
        }
    }, [bannerData?.advertising?.cost, bannerData.name, bannerData.organization.name, href]);

    return <VitrinsSidebarBanner bannerData={sidebarData} onClick={handleClick} displayPixel={BannerPixel} />;
});

export default SidebarBanner;
