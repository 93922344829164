import type { FC } from 'react';
import React, { memo } from 'react';

import { Layout } from '@sravni/react-design-system';

import type { IFilters } from '@src/@types/microcredits';
import { Calculator, useGetCalculatorHeadingByRoute } from 'features/Calculator';
import { Country } from 'shared/config/internalization';

import { getFieldsPropsByCountry } from '../lib';

import styles from './CalculatorWithHeaders.module.scss';

interface Props {
    filters: Partial<IFilters>;
    country?: Country;
    shouldScrollToCalculatorAfterChange?: boolean;
}

export const CalculatorWithHeaders: FC<Props> = memo(({ country, ...props }) => {
    const headings = useGetCalculatorHeadingByRoute();

    return (
        <Layout className={styles.layout}>
            <Calculator {...headings} {...getFieldsPropsByCountry(country)} {...props} />
        </Layout>
    );
});

CalculatorWithHeaders.displayName = 'CalculatorWithHeaders';

CalculatorWithHeaders.defaultProps = {
    country: Country.Russia,
    shouldScrollToCalculatorAfterChange: true,
};
