import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useCurrentLocation } from '@src/reducers/locations';
import { fetchBestOffers } from '@src/reducers/microcredits';
import { useSeoState } from '@src/reducers/seo';

const SEO_BEST_PRODUCTS_TYPE = 'bestproduct';

export const useBestProducts = () => {
    const dispatch = useDispatch();
    const { seo } = useSeoState();
    const currentLocation = useCurrentLocation();

    useEffect(() => {
        if (!seo) return;
        if (!currentLocation) return;
        const needsBestProducts = seo.contentBlocks?.some((item) => item.type === SEO_BEST_PRODUCTS_TYPE);

        if (!needsBestProducts) return;

        dispatch(
            fetchBestOffers({
                location: currentLocation,
            }),
        );
    }, [dispatch, seo, currentLocation]);
};
