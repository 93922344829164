import { KAZAKHSTAN_AMOUNT_FIELD_SETTINGS } from '@src/constants/amountSettings';
import { KAZAKHSTAN_RATE_FIELD_SETTINGS } from '@src/constants/rateSettings';
import { formatMoney } from '@src/helpers/formatMoney';
import { Country, CurrencySymbol } from 'shared/config/internalization';

const kazakhstanFieldsProps = {
    amountProps: {
        label: 'Сумма займа',
        postfix: CurrencySymbol.KZT,
        min: KAZAKHSTAN_AMOUNT_FIELD_SETTINGS.min,
        max: KAZAKHSTAN_AMOUNT_FIELD_SETTINGS.max,
        helperText: `от ${formatMoney(KAZAKHSTAN_AMOUNT_FIELD_SETTINGS.min, {
            withSymbol: false,
        })} до ${formatMoney(KAZAKHSTAN_AMOUNT_FIELD_SETTINGS.max, { symbol: CurrencySymbol.KZT })}`,
    },
    rateProps: {
        label: 'Ставка',
        postfix: '%',
        min: KAZAKHSTAN_RATE_FIELD_SETTINGS.min,
        max: KAZAKHSTAN_RATE_FIELD_SETTINGS.max,
        helperText: 'от 0,01 до 0,99%',
    },
};

const FIELDS_PROPS_BY_COUNTRY = {
    [Country.Kazakhstan]: kazakhstanFieldsProps,
};

export const getFieldsPropsByCountry = (country: Country) => FIELDS_PROPS_BY_COUNTRY[country];
